import { isProd } from "@/utils/env-related";
const hosts = {
  production: {
    imOSS: "https://im-images.qiandaoapp.com",
    uploadOSS: "https://svc-upload-dev.oss-cn-beijing.aliyuncs.com",
    community: "https://api.qiandaoapp.com/cactus-api",
    poly: "https://api.qiandaoapp.com/poly",
    trade: "https://api.qiandaoapp.com/trade",
    notification: "https://api.qiandaoapp.com/notification",
    config: "https://config-cdn.qiandaoapp.com",
    configCommon: "https://config-cdn.qiandaoapp.com",
    cdnHost: "https://cdn.qiandaoapp.com",
    user: "https://api.qiandaoapp.com/user",
    upload: "https://api.qiandaoapp.com/upload",
    messenger: "https://api.qiandaoapp.com/im",
    search: "https://gapi.qiandaoapp.com/plast",
    auction: "https://gapi.qiandaoapp.com/auctioneer",
    order: "https://gapi.qiandaoapp.com/order",
    groupon: "https://gapi.qiandaoapp.com/groupon",
    poster: "https://api.qiandaoapp.com/poster",
    payment: "https://api.qiandao.cn/payment",
    supplier: "https://gapi.qiandaoapp.com/supplier",
    gapi: "https://gapi.qiandaoapp.com",
    api: "https://api.qiandao.cn",
    sankeng: "https://sankeng.qiandaoapp.com",
    paymentH5: "https://pay.linjieapp.com/h5/pay",
    nft: "https://api.qiandao.cn/nft-web",
    cactus: "https://api.qiandao.cn/cactus-api",
  },
  development: {
    imOSS: "https://dev-im-images.qiandaoapp.com",
    uploadOSS: "https://svc-upload-dev.oss-cn-beijing.aliyuncs.com",
    community: "https://dev-api.qiandaoapp.com/cactus-api",
    poly: "https://dev-api.qiandaoapp.com/poly",
    trade: "https://dev-api.qiandaoapp.com/trade",
    notification: "https://dev-api.qiandaoapp.com/notification",
    config: "https://dev-config-cdn.qiandaoapp.com",
    configCommon: "https://dev-config-cdn.qiandaoapp.com",
    cdnHost: "https://dev-cdn.qiandaoapp.com",
    user: "https://dev-api.qiandaoapp.com/user",
    upload: "https://dev-api.qiandaoapp.com/upload",
    messenger: "https://dev-api.qiandaoapp.com/im",
    search: "https://dev-gapi.qiandaoapp.com/plast",
    auction: "https://dev-gapi.qiandaoapp.com/auctioneer",
    order: "https://dev-gapi.qiandaoapp.com/order",
    groupon: "https://dev-gapi.qiandaoapp.com/groupon",
    poster: "https://dev-api.qiandaoapp.com/poster",
    payment: "https://dev-api.qiandao.cn/payment",
    supplier: "https://dev-gapi.qiandaoapp.com/supplier",
    gapi: "https://dev-gapi.qiandaoapp.com",
    api: "https://dev-api.qiandao.cn",
    sankeng: "https://sankeng.qiandaoapp.com",
    paymentH5: "https://dev-pay.linjieapp.com/h5/pay",
    nft: "https://dev-api.qiandao.cn/nft-web",
    cactus: "https://dev-api.qiandao.cn/cactus-api",
    // nft: "https://api.qiandao.cn/nft-web",
  },
};

export default isProd ? hosts.production : hosts.development;
// export default (() => {
//   if (isDev) {
//     const localEnv: any = localStorage.getItem("LOCAL_TEST_ENV_NAME");
//     if (localEnv) {
//       return (hosts as any)[localEnv];
//     } else {
//       return hosts.development;
//     }
//   } else {
//     return hosts.production
//   }
// })();
